import axios from 'axios'

export const WEB_BASE_URL = '/api/v1/'

const tokens = document.cookie.split(';')
let token = ''
for (let i = 0; i < tokens.length; i++) {
  if (tokens[i].indexOf('csrftoken=') > -1) {
    token = tokens[i].split('csrftoken=')[1]
  }
}

export default axios.create({
  baseURL: WEB_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': token
  }
})
